<template>
  <div class="container">

    <el-form :inline="true">
      <el-form-item label="状态">
        <el-select v-model="query.status" placeholder="选择状态" clearable>
          <el-option label="待审核" :value="0"></el-option>
          <el-option label="通过" :value="1"></el-option>
          <el-option label="拒绝" :value="2"></el-option>
        </el-select>
      </el-form-item>
      
      <el-form-item label="教练">
        <coachSelect :coachid.sync="coach_id"></coachSelect>
      </el-form-item>
      <el-form-item label="提交时间">
        <el-date-picker
          v-model="query.createTime"
          type="daterange"
          range-separator="至"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
    </el-form>

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #name="{ row }">
        <div>
          <link-to :to="{ name: 'coachDetails', query: { id: row.coach_id } }">
            {{ row.coach_name }}</link-to
          >
        </div>
      </template>
 <template #type="{ row }">
        <div>
          {{ getLabel("typeMap", row.type) }}
        </div>
      </template>
      <template #status="{ row }">
        <el-tag size="small" type="info" v-if="row.status === 0">待审核</el-tag>
        <el-tag size="small" type="success" v-if="row.status === 1"
          >通过</el-tag
        >
        <el-tag size="small" type="danger" v-if="row.status === 2">拒绝</el-tag>
      </template>
      <template #handle="{ row }">
        <el-button
          size="small"
          v-if="row.status === 0"
          type="success"
          @click="updateStatus(row)"
          >审核</el-button
        >
        <el-button size="small" @click="openInfo(row)" type="primary"
          >详情</el-button
        >
      </template>
    </auto-table>

    <!-- 认证审核意见 -->
    <el-dialog title="认证审核意见" :visible.sync="show" width="30%">
      <div>
        <el-form label-width="80px">
          <el-form-item label="教练">
            {{ curAuth.coach_name }}
          </el-form-item>
          <el-form-item label="审核结果">
            <el-tag size="small" type="info" v-if="curAuth.status === 0"
              >待审核</el-tag
            >
            <el-tag size="small" type="success" v-if="curAuth.status === 1"
              >通过</el-tag
            >
            <el-tag size="small" type="danger" v-if="curAuth.status === 2"
              >拒绝</el-tag
            >
          </el-form-item>
          <el-form-item label="审核人">
            {{ curAuth.admin_name }}
          </el-form-item>
          <el-form-item label="审核时间">
            {{ curAuth.update_time }}
          </el-form-item>
          <el-form-item label="说明" v-if="curAuth.status === 1">
            {{ curAuth.remark }}
          </el-form-item>
          <el-form-item label="拒绝理由" v-if="curAuth.status === 2">
            {{ curAuth.refuse }}
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import coachSelect from '@/components/select/coachSelect.vue';

export default {
  components:{
    coachSelect
  },
  data() {
    return {
      DataList: [],
      coach_id:'',
      query: {
        status: 0,
        real_name: "",
        createTime: [],
        type:''
      },
       typeMap: [
        {
          label: "教练认证",
          value: "auth",
        },
        {
          label: "学生认证",
          value: "stu",
        },
      ],
      Option: [
        { name: "教练", value: "name", type: "custom" },
        { name: "电话", value: "map.coach.phone"},
        { name: "城市", value: "map.coach.city" },
        { name: "状态", value: "status", type: "custom" },
        { name: "类型", value: "type", type: "custom" },
        { name: "提交时间", value: "create_time" },
        { name: "审核时间", value: "update_time" },
        { name: "操作", value: "handle", type: "custom", width: "300px" },
      ],
      Total: 0,
      curAuth: {},
      show: false,
    };
  },


mounted() {
   this.getList(1);
},
  methods: {
     load() {
      if (this.DataList.length == 0) {
        this.getList(1);
      }
    },
    getLabel(list, v) {
      for (let index = 0; index < this[list].length; index++) {
        if (this[list][index].value === v) {
          return this[list][index].label;
        }
      }
    },
    openInfo(row) {
      this.curAuth = row;
      this.show = true;
    },
    updateStatus(row) {
      this.$router.push({
        name: "coachDetails",
        query: { id: row.coach_id, auth_id: row.id },
      });
    },
    //列表
    getList(v) {
      let page = v || 1;
      let params = {
        currentPage: page,
        pageSize: 10,
        status: this.query.status,
       coach_id:this.coach_id || null,
       type:'stu'
      };
      if (this.query.createTime !== null && this.query.createTime.length > 0) {
        params.start_time = this.query.createTime[0] + " 00:00:00";
        params.end_time = this.query.createTime[1] + " 23:59:59";
      }

      this.$axios({
        url: "/user/authApply/queryManagerListPage",
        params,
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>