<template>
  <div class="container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="在校生会员" name="vip">
        <coachVipApply ref="vip"></coachVipApply>
      </el-tab-pane>
        <el-tab-pane label="特约教练申请" name="sign">
        <coachSignApply ref="sign"></coachSignApply>
      </el-tab-pane>
      <el-tab-pane label="保证金提现申请" name="bond">
        <coachBondApply ref="bond"></coachBondApply>
      </el-tab-pane>
        <el-tab-pane label="公会开发人申请" name="dev">
        <coachDevApply ref="dev"></coachDevApply>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import coachBondApply from './components/coachBondApply.vue';
import coachDevApply from './components/coachDevApply.vue';
import coachSignApply from './components/coachSignApply.vue';
import coachVipApply from "./components/coachVipApply.vue";

export default {
  components: {
    coachVipApply,
    coachSignApply,
    coachBondApply,
    coachDevApply
  },
  data() {
    return {
      activeName: "vip",
    };
  },

  mounted() {},

  methods: {
    handleClick(tab) {
      this.$refs[tab.name].load();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>